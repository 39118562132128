import { Component, OnInit, AfterViewInit, HostListener, ChangeDetectorRef } from '@angular/core'
import { UserService } from '@service/user/user.service'
import { TranslateService } from '@ngx-translate/core'
import { DateAdapter } from '@angular/material/core'
import { LoadingService } from '@service/loading.service'
import { MobileService } from '@service/mobile.service'
import { Angulartics2Facebook } from 'angulartics2'
import { EntityTypeConfigService } from '@service/entity-type-config.service'
import { BlogService } from '@service/blog.service'
import { AdvertService } from '@service/advert/advert.service'
import { JobService } from '@service/job.service'
import { WikiService } from '@service/wiki.service'
import { environment } from '@environments/environment'
import { CategoriesService } from '@service/categories.service'
import { CategoryType } from '@enum/category-type'
import { Router } from '@angular/router'
import { DialogService } from '@service/dialog.service'
import * as Bowser from "bowser"
import { MatDialog } from '@angular/material/dialog'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.mobileService.checkMobile()
  }

  public stateClass: string = ''

  public loading: boolean = false

  public isDialogOpen = false;

  constructor(
    private userService: UserService,
    private translateService: TranslateService,
    private dateAdapter: DateAdapter<Date>,
    public loadingService: LoadingService,
    private categoriesService: CategoriesService,
    private angulartics2Facebook: Angulartics2Facebook,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private dialogService: DialogService,
    private entityTypeConfigService: EntityTypeConfigService,
    private blogService: BlogService,
    private jobService: JobService,
    private advertService: AdvertService,
    private wikiService: WikiService,
    public mobileService: MobileService,
    private dialog: MatDialog
  ) {

    /*
      This will force angular router to reload the components (and by that the data) even when only parameters change
      i.e. you navigate from /blog/detail/1005/test to /blog/detail/265/blubb
    */
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    /*
      for some reason angular does not recognize this exact template change and throws a
      "Expression ___ has changed after it was checked" error
      if you dont use the ChangeDetectorRef
    */
    this.loadingService.onLoadingChange.subscribe(loading => {
      this.loading = loading
      this.cdr.detectChanges()
    })

    /*
      needs to be done here to avoid circular deps
      entityTypeConfigService needs to know blogservice etc.
      and blogservice etc. need to know their entitytypeconfig
    */
    this.entityTypeConfigService.entitytypes['blog'].service = this.blogService
    this.entityTypeConfigService.entitytypes['job'].service = this.jobService
    this.entityTypeConfigService.entitytypes['advert'].service = this.advertService
    this.entityTypeConfigService.entitytypes['wiki'].service = this.wikiService

    if (environment.production) {
      this.angulartics2Facebook.startTracking()
    }

    this.translateService.setTranslation('de', {

      ARCHITECT: 'Architekt',
      TRADER: 'Handel',
      MANUFACTURERS: 'Hersteller',
      PLANER: 'Planer',
      SCHOOL: 'Schule',
      OTHERS: 'Sonstige',
      ORG: 'Verband/Organisation',

      'SEC1': 'Fahrzeuge, Maschinen, Werkzeuge',
      'SEC2': 'Holz und Holzwerkstoffe',
      'SEC3': 'Baustoffe',
      'SEC4': 'Dachabdichtung',
      'SEC5': 'Sonstiges',
      'SEC6': 'Verankerungen, Verbindungen, Befestigungen',
      'SEC7': 'Wärme- und Schalldämmung',
      'SEC8': 'Dachdeckungen',
      'SEC9': 'Betriebliche Organisationsmittel',
      'SEC10': 'Dachgeschossbau/Trockenbau',
      'SEC11': 'Gerüste, Leitern, Krane',
      'SEC12': 'Entwässerungen',
      'SEC13': 'Außenwandbekleidung',
      'SEC14': 'Arbeitssicherheit',
      'SEC15': 'Arbeitskleidung',
      'SEC16': 'Dienstleistungen',
      'SEC17': 'Onlinedienste',
      'SEC18': 'Software',
      'SEC19': 'Holzbau',
      'SEC20': 'Bildung',
      'SEC21': 'Architektur',
      'SEC22': 'Medien',
      'SEC23': 'Messe',

      MAN: 'Männlich',
      FEMALE: 'Weiblich',
      DIVERSE: 'Divers',

      PROFILE: {
        PRIVACIES: {
          0: 'für alle',
          1: 'nur ich',
        },
        STATISTICS: {
          statistics_count_adverts_asked: 'Anzeigen kontaktiert',
          statistics_count_adverts_created: 'Anzeigen erstellt',
          statistics_count_blogs_created: 'Blogbeiträge geschrieben',
          statistics_count_comments_created: 'Kommentare geschrieben',
          statistics_count_jobs_asked: 'Auf Jobangebote geantwortet',
          statistics_count_jobs_created: 'Jobs angeboten',
          statistics_count_likes_placed: 'Likes gegeben',
          statistics_count_likes_received: 'Likes bekommen',
          statistics_count_videos_created: 'Videos erstellt',
          statistics_count_wikis_created: 'Wikibeiträge erstellt',
        },
        RULES_SINGULAR: {
          first_login: 'erster Login',
          completed_profile: 'Profil vollständig',
          count_blogs_created: 'Blogeintrag geschrieben',
          count_comments_created: 'Kommentar geschrieben',
          count_likes_placed: 'Like gegeben',
          count_likes_received: 'Like bekommen',
          count_adverts_created: 'Anzeige erstellt',
          count_adverts_asked: 'Anzeige kontaktiert',
          count_jobs_created: 'Job angeboten',
          count_jobs_asked: 'Jobangebot angefragt',
          count_videos_created: 'Video erstellt',
          completed_profile_personal_information: 'Persönliche Informationen angegeben',
          completed_profile_network_information: 'Netzwerk Informationen angegeben',
          completed_profile_address: 'Persönliche Adresse angegeben',
          completed_profile_career: 'Beruflicher Werdegang angegeben',
          completed_profile_certificate_uploaded: 'Zertifikat hochgeladen',
          completed_profile_certificate_verified: 'Zertifikat verifiziert',
          completed_profile_uploaded_profile_wall_image: 'Profil Titelbild hochgeladen',
          completed_profile_uploaded_profile_image: 'Profilbild hochgeladen'
        },
        RULES_PLURAL: {
          count_blogs_created: 'Blogeinträge geschrieben',
          count_comments_created: 'Kommentare geschrieben',
          count_likes_placed: 'Likes gegeben',
          count_likes_received: 'Likes bekommen',
          count_adverts_created: 'Anzeigen erstellt',
          count_adverts_asked: 'Anzeigen kontaktiert',
          count_jobs_created: 'Jobs angeboten',
          count_jobs_asked: 'Jobangebote angefragt',
          count_videos_created: 'Videos erstellt',
          count_wikis_created: 'Wikibeiträge erstellt',
        },
      },
    })

    translateService.setDefaultLang('de')
    translateService.use('de')

    this.dateAdapter.setLocale('de')

    // check if mobile
    this.mobileService.checkMobile()

    // check if ios or android
    const browser = Bowser.getParser(window.navigator.userAgent)
    this.mobileService.isIos = browser.getOSName() == "iOS" ? true : false
    this.mobileService.isAndroid = browser.getOSName() == "Android" ? true : false

    console.log("os", browser.getOSName())
  }

  public ngOnInit() {

    // find blog video category (by fetching blog categories)
    this.categoriesService.getCategoriesFromServer(CategoryType.blog + '/get', CategoryType.blog)

    // if link with login -> open login dialog
    const urlParams = new URLSearchParams(window.location.search)
    const loginParam = urlParams.get('login')

    if (loginParam != null) {
      this.dialogService.showLoginDialog()
    }

    // If the user watches the website on a mobile device nag him to download the app
    if (
      (this.mobileService.isIos || this.mobileService.isAndroid)
      && !this.mobileService.isSocialMediaInAppBrowser
    ) {
      this.dialogService.showAppNagDialog()
    }

    this.dialog.afterOpened.subscribe(() => {
      this.isDialogOpen = true;
    });

    this.dialog.afterAllClosed.subscribe(() => {
      this.isDialogOpen = false;
    });
  }

  // private setStateCssClass(data:Data) {

  //   if (!data || typeof data.title == "undefined")
  //     return

  //   var targetStateName = data.name.replace(/\./g, '-')
  //   this.stateClass = targetStateName
  // }

  public ngAfterViewInit() {
    this.userService.tokenHealtCheck()
    setInterval(() => {
      this.userService.tokenHealtCheck()
    }, 5000)
  }
}
